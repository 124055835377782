import { useMediaQuery } from '@mui/material';
import { Suspense, lazy, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, useHistory } from 'react-router-dom';

import { themePages } from '@components/Sidebar';
import { useTabs } from '@components/Tabs/tabs.routes';
import { useOrganizationPermissions } from '@features/Organization/organizationSlice';
import { auth } from '@utils/firebase';

import { RouteContainer } from './RouteContainer';

const AppIntro = lazy(() => import('@components/AppIntro'));
const Admin = lazy(() => import('@features/Admin'));
const SendInvite = lazy(() => import('@features/Admin/Invites/SendInvite'));
const LessonDetailRouter = lazy(() => import('@features/Lessons/LessonDetail'));
const MediaDetail = lazy(() => import('@features/Media/MediaDetail'));
const EventDetail = lazy(() => import('@features/Schedule/EventDetail'));
const GlobalDiscounts = lazy(
  () => import('@features/Admin/Billing/GlobalDiscounts')
);
const GlobalHourlyRate = lazy(
  () => import('@features/Admin/Billing/GlobalHourlyRate')
);
const GlobalInvoiceRules = lazy(
  () => import('@features/Admin/Billing/GlobalInvoiceRules')
);
const GlobalRefundPolicies = lazy(
  () => import('@features/Admin/Billing/GlobalRefundPolicies')
);
const Invoices = lazy(() => import('@features/Admin/Invoices'));
const InvoiceDetail = lazy(
  () => import('@features/Admin/Invoices/InvoiceDetail')
);
const ProductDetail = lazy(
  () => import('@features/Admin/Products/ProductDetail')
);
const GlobalLastMinuteCancellations = lazy(
  () => import('@features/Admin/Scheduling/GlobalLastMinuteCancellations')
);
const GlobalNoShows = lazy(
  () => import('@features/Admin/Scheduling/GlobalNoShows')
);
const GlobalReschedulePolicies = lazy(
  () => import('@features/Admin/Scheduling/GlobalReschedulePolicies')
);
const GlobalTeachingHours = lazy(
  () => import('@features/Admin/Scheduling/GlobalTeachingHours')
);
const ContactDetail = lazy(() => import('@features/Contacts/ContactDetail'));
const ContactGroup = lazy(() => import('@features/Contacts/ContactGroup'));
const Lessons = lazy(() => import('@features/Lessons'));

const basePath = '/:organizationSlug';
const AuthenticatedRoutes = () => {
  const [user, loading] = useAuthState(auth);
  const router = useHistory();
  const permission = useOrganizationPermissions();

  useEffect(() => {
    if (!user && !loading) {
      router.push('/');
    }
  }, [user, loading, router]);

  const tabs = useTabs({ type: 'routes' });

  const isDesktop = useMediaQuery('(min-width:747px)');

  if (loading) {
    return <div>auth loading…</div>;
  }

  if (!permission) {
    return <div>loading permissions…</div>;
  }

  if (!user) {
    return <div>no user for authenticated route</div>;
  }

  return (
    <AppIntro>
      <div data-auth="signed-in" style={{ height: '100%' }}>
        <Suspense fallback="loading…">
          <RouteContainer>
            <Route
              path={`${basePath}/admin/invoices`}
              render={() => <Invoices />}
              exact
            />
            <Route
              path={`${basePath}/admin/invoices/:invoiceId`}
              render={() => <InvoiceDetail />}
              exact
            />
            <Route
              path={`${basePath}/lesson-notes`}
              render={() => <Lessons />}
              exact
            />
            <Route
              path={`${basePath}/media/:mediaId`}
              render={() => <MediaDetail />}
              exact
            />
            <Route
              path={`${basePath}/lesson-notes/:lessonId`}
              render={() => <LessonDetailRouter />}
              exact
            />
            <Route
              path={`${basePath}/admin/products/:productId`}
              render={() => <ProductDetail />}
            />
            <Route path={`${basePath}/contacts/:contactId`}>
              {isDesktop ? <ContactGroup showDetails /> : <ContactDetail />}
            </Route>
            <Route path={`${basePath}/send-invite/:contactId`}>
              <SendInvite />
            </Route>
            <Route path={`${basePath}/schedule/:eventId`}>
              <EventDetail />
            </Route>
            <Route
              path={`${basePath}/admin/reschedule-policies`}
              render={() => <GlobalReschedulePolicies />}
              exact
            />
            <Route
              path={`${basePath}/admin/last-minute-cancellations`}
              render={() => <GlobalLastMinuteCancellations />}
              exact
            />
            <Route
              path={`${basePath}/admin/no-shows`}
              render={() => <GlobalNoShows />}
              exact
            />
            <Route
              path={`${basePath}/admin/invoice-rules`}
              render={() => <GlobalInvoiceRules />}
              exact
            />
            <Route
              path={`${basePath}/admin/discounts`}
              render={() => <GlobalDiscounts />}
              exact
            />
            <Route
              path={`${basePath}/admin/teaching-hours`}
              render={() => <GlobalTeachingHours />}
              exact
            />
            <Route
              path={`${basePath}/admin/refund-policies`}
              render={() => <GlobalRefundPolicies />}
              exact
            />
            <Route
              path={`${basePath}/admin/hourly-rate`}
              render={() => <GlobalHourlyRate />}
              exact
            />

            <Route
              path={`${basePath}/admin/add-product`}
              render={() => <Admin />}
            />

            {themePages.map(({ url, Component }) => (
              <Route
                key={url}
                path={`${basePath}${url}`}
                render={() => <Component />}
              />
            ))}

            {tabs.map(({ url, Component, paths, exact = true }) => (
              <Route
                key={url}
                path={paths ?? `${basePath}${url}`}
                exact={exact}
                render={() => <Component />}
              />
            ))}
          </RouteContainer>
        </Suspense>
      </div>
    </AppIntro>
  );
};

export default AuthenticatedRoutes;
